@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: redditsans;
  font-style: normal;
  font-weight: 300;
  src:
    url(./fonts/reddit-sans/RedditSans-Light.woff2) format("woff2"),
    url(./fonts/reddit-sans/RedditSans-Light.woff) format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: normal;
  font-weight: 400;
  src:
    url(./fonts/reddit-sans/RedditSans-Regular.woff2) format("woff2"),
    url(./fonts/reddit-sans/RedditSans-Regular.woff) format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: normal;
  font-weight: 600;
  src:
    url(./fonts/reddit-sans/RedditSans-SemiBold.woff2) format("woff2"),
    url(./fonts/reddit-sans/RedditSans-SemiBold.woff) format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: normal;
  font-weight: 700;
  src:
    url(./fonts/reddit-sans/RedditSans-Bold.woff2) format("woff2"),
    url(./fonts/reddit-sans/RedditSans-Bold.woff) format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: normal;
  font-weight: 800;
  src:
    url(./fonts/reddit-sans/RedditSans-ExtraBold.woff2) format("woff2"),
    url(./fonts/reddit-sans/RedditSans-ExtraBold.woff) format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: italic;
  font-weight: 300;
  src:
    url(./fonts/reddit-sans/RedditSans-LightItalic.woff2) format("woff2"),
    url(./fonts/reddit-sans/RedditSans-LightItalic.woff) format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: italic;
  font-weight: 400;
  src:
    url(./fonts/reddit-sans/RedditSans-Italic.woff2) format("woff2"),
    url(./fonts/reddit-sans/RedditSans-Italic.woff) format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: italic;
  font-weight: 600;
  src:
    url(./fonts/reddit-sans/RedditSans-SemiBoldItalic.woff2) format("woff2"),
    url(./fonts/reddit-sans/RedditSans-SemiBoldItalic.woff) format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: italic;
  font-weight: 700;
  src:
    url(./fonts/reddit-sans/RedditSans-BoldItalic.woff2) format("woff2"),
    url(./fonts/reddit-sans/RedditSans-BoldItalic.woff) format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: italic;
  font-weight: 800;
  src:
    url(./fonts/reddit-sans/RedditSans-ExtraBoldItalic.woff2) format("woff2"),
    url(./fonts/reddit-sans/RedditSans-ExtraBoldItalic.woff) format("woff");
}

html,
body {
  font-family: redditsans, sans-serif;
  @apply text-gray-800 text-lg;
}

.row {
  @apply container mx-auto max-w-screen-md;
}

a {
  @apply text-sky-400 underline;
}

a.dark {
  @apply underline text-inherit;
}

a.logo {
  @apply no-underline text-inherit;
}

dl {
  @apply grid gap-4 mt-2;
  grid-template-columns: min-content auto;
}

dl dt {
  @apply font-bold;
}

dl dd {
  @apply italic;
}

p {
  @apply mb-2;
}

h2 {
  @apply text-4xl;
}

h3 {
  @apply text-3xl mt-8;
}

h4 {
  @apply text-2xl mt-4 mb-2;
}

ul.list {
  @apply list-disc list-inside;
}
