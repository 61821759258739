*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.flex {
  display: flex;
}

.h-40 {
  height: 10rem;
}

.w-1\/2 {
  width: 50%;
}

.w-full {
  width: 100%;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.gap-2 {
  gap: .5rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-dashed {
  border-style: dashed;
}

.border-zinc-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 216 / var(--tw-border-opacity));
}

.border-b-sky-600 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(2 132 199 / var(--tw-border-opacity));
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}

.p-2 {
  padding: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-40 {
  opacity: .4;
}

@font-face {
  font-family: redditsans;
  font-style: normal;
  font-weight: 300;
  src: url("RedditSans-Light.085f1607.woff2") format("woff2"), url("RedditSans-Light.cfb6e6c4.woff") format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: normal;
  font-weight: 400;
  src: url("RedditSans-Regular.c1c563ab.woff2") format("woff2"), url("RedditSans-Regular.e8c19463.woff") format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: normal;
  font-weight: 600;
  src: url("RedditSans-SemiBold.e018d51d.woff2") format("woff2"), url("RedditSans-SemiBold.7e147d40.woff") format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: normal;
  font-weight: 700;
  src: url("RedditSans-Bold.943e0789.woff2") format("woff2"), url("RedditSans-Bold.4a8e4792.woff") format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: normal;
  font-weight: 800;
  src: url("RedditSans-ExtraBold.527617e5.woff2") format("woff2"), url("RedditSans-ExtraBold.6e1876c6.woff") format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: italic;
  font-weight: 300;
  src: url("RedditSans-LightItalic.1e17c59b.woff2") format("woff2"), url("RedditSans-LightItalic.7b9cbb3e.woff") format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: italic;
  font-weight: 400;
  src: url("RedditSans-Italic.2de1065c.woff2") format("woff2"), url("RedditSans-Italic.bf1e9ea3.woff") format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: italic;
  font-weight: 600;
  src: url("RedditSans-SemiBoldItalic.24078e7d.woff2") format("woff2"), url("RedditSans-SemiBoldItalic.005f197a.woff") format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: italic;
  font-weight: 700;
  src: url("RedditSans-BoldItalic.8a3ef44d.woff2") format("woff2"), url("RedditSans-BoldItalic.1b42db6b.woff") format("woff");
}

@font-face {
  font-family: redditsans;
  font-style: italic;
  font-weight: 800;
  src: url("RedditSans-ExtraBoldItalic.ad4dd454.woff2") format("woff2"), url("RedditSans-ExtraBoldItalic.60d2f175.woff") format("woff");
}

html, body {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
  font-family: redditsans, sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.row {
  width: 100%;
}

@media (width >= 640px) {
  .row {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .row {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .row {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .row {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .row {
    max-width: 1536px;
  }
}

.row {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}

a {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity));
  text-decoration-line: underline;
}

a.dark {
  color: inherit;
  text-decoration-line: underline;
}

a.logo {
  color: inherit;
  text-decoration-line: none;
}

dl {
  grid-template-columns: min-content auto;
  gap: 1rem;
  margin-top: .5rem;
  display: grid;
}

dl dt {
  font-weight: 700;
}

dl dd {
  font-style: italic;
}

p {
  margin-bottom: .5rem;
}

h2 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

h3 {
  margin-top: 2rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

h4 {
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: 2rem;
}

ul.list {
  list-style-type: disc;
  list-style-position: inside;
}

.hover\:bg-sky-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

@media (width >= 1024px) {
  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}

.vlzxcW_scenarioCard {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
  text-align: right;
  border-radius: .5rem;
  width: 33.3333%;
  height: 22vh;
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  overflow: hidden;
}

.vlzxcW_scenarioCardContainer {
  flex-direction: row;
  gap: .5rem;
  display: flex;
}

.vlzxcW_scenarioKycImage {
  z-index: -1;
  position: relative;
}

.vlzxcW_imgOverlay {
  z-index: 0;
  opacity: .2;
  width: 80%;
  margin-top: -1%;
  margin-left: 7%;
  display: block;
  transform: rotate(15deg);
}

.vlzxcW_demoBox {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
  background: linear-gradient(to bottom right, #5c62ec, #0ea5e9);
  border-radius: .5rem;
  padding: 2rem 2rem 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  display: flex;
  overflow: hidden;
}

.vlzxcW_demoBox > div {
  text-align: center;
  width: 50%;
}

.vlzxcW_demoLink {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 9999px;
  padding: .5rem 1rem;
  text-decoration-line: none;
}

.vlzxcW_demoLink:hover {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.vlzxcW_demoBoxExpected {
  margin-top: 1rem;
  font-size: .75rem;
  line-height: 1rem;
}

.vlzxcW_footer {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-top-color: rgb(228 228 231 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
  flex-direction: row;
  margin-top: 2.5rem;
  padding: 1rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.vlzxcW_footer .vlzxcW_horizontal {
  list-style-type: none;
}

.vlzxcW_footer .vlzxcW_horizontal li {
  padding-left: .5rem;
  padding-right: .5rem;
  display: inline;
}

/*# sourceMappingURL=index.83cd5269.css.map */
